import { Classes, Toaster } from '@blueprintjs/core';
import { theme as darkplusTheme } from '@bithero/darkplus-monaco';
import { editor as monacoEditor, languages as monacoLanguages, Range } from 'monaco-editor';
import { loadWASM } from 'onigasm';
import { Registry, StackElement, INITIAL } from 'monaco-textmate';
import { StandaloneServices } from 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices.js';
import { IStandaloneThemeService } from 'monaco-editor/esm/vs/editor/standalone/common/standaloneTheme.js';

import { createControls, createIcon } from './components';
import { NavigateFunction } from 'react-router-dom';
import { runnerInstance } from './runner';
import { TerminalState, defaultTerminalState, term2html } from 'term2html';

const registry = new Registry({
    getGrammarDefinition: async (scopeName) => {
        return {
            format: 'json',
            content: await (await fetch(new URL('/assets/lapyst.tmLanguage.json', import.meta.url).href)).text(),
        };
    },
});

class TokenizerState implements monacoLanguages.IState {
    constructor(private _ruleStack: StackElement) {}
    public get ruleStack(): StackElement {
        return this._ruleStack
    }
    public clone(): monacoLanguages.IState {
        return new TokenizerState(this._ruleStack);
    }
    public equals(other: monacoLanguages.IState): boolean {
        if (!other || !(other instanceof TokenizerState) || other !== this || other._ruleStack !== this._ruleStack) {
            return false;
        }
        return true;
    }
}

function TMToMonacoToken(scopes: string[]) {
    let scopeName = "";
    // get the scope name. Example: cpp , java, haskell
    for (let i = scopes[0].length - 1; i >= 0; i -= 1) {
        const char = scopes[0][i];
        if (char === ".") {
            break;
        }
        scopeName = char + scopeName;
    }

    const themeService = StandaloneServices.get(IStandaloneThemeService);

    for (let i = scopes.length - 1; i >= 0; i -= 1) {
        const scope = scopes[i];
        for (let i = scope.length - 1; i >= 0; i -= 1) {
            const char = scope[i];
            if (char === ".") {
                const token = scope.slice(0, i);
                if (themeService._theme._tokenTheme._match(token + "." + scopeName)._foreground > 1) {
                    return token + "." + scopeName;
                }
                if (themeService._theme._tokenTheme._match(token)._foreground > 1) {
                    return token;
                }
            }
        }
    }

    return "";
}

async function initMonaco() {
    // modify the backgroundcolor here a bit
    darkplusTheme.colors["editor.background"] = '#00000000';
    monacoEditor.defineTheme('darkplus', darkplusTheme);

    await loadWASM(new URL("/assets/wasm/onigasm.wasm", import.meta.url).href);

    monacoLanguages.register({ id: 'lapyst' });
    monacoLanguages.setLanguageConfiguration('lapyst', {
        comments: {
            lineComment: "#",
            blockComment: [ "/*", "*/" ]
        },
        brackets: [
            ["(", ")"],
            ["[", "]"],
            ["{", "}"]
        ],
        autoClosingPairs: [
            { open: "\"", close: "\"", notIn: ["string"] },
            { open: "/**", close: " */", notIn: ["string"] },
            { open: "(", close: ")", notIn: ["string"] },
            { open: "[", close: "]", notIn: ["string"] },
            { open: "{", close: "}", notIn: ["string"] }
        ],
        surroundingPairs: [
            { open: "(", close: ")" },
            { open: "[", close: "]" },
            { open: "{", close: "}" },
            { open: "\"", close: "\"" }
        ],
    });

    // tm-to-monaco token provider based on https://github.com/zikaari/monaco-editor-textmate/
    const grammar = await registry.loadGrammar("source.lapyst");
    monacoLanguages.setTokensProvider("lapyst", {
        getInitialState() { return new TokenizerState(INITIAL); },
        tokenize(line: string, state: TokenizerState) {
            const res = grammar.tokenizeLine(line, state.ruleStack);
            return {
                endState: new TokenizerState(res.ruleStack),
                tokens: res.tokens.map(token => ({
                    ...token,
                    scopes: TMToMonacoToken(token.scopes),
                })),
            };
        },
    });
}

function isDarkTheme(element: Element | Text | null | undefined): boolean {
    return element != null && element instanceof Element && element.closest(`.${Classes.DARK}`) != null;
}

let nextEditorNum = 1;

export interface IEditorInstance {
    code: string
    decorations: { line: number, conum: number }[]
    flags: string[]
    editor: monacoEditor.IEditor
    id: number
    termState: TerminalState
    outputElementRef: React.MutableRefObject<HTMLDivElement> | null
}

const editorStore: IEditorInstance[] = [];
(window as any).editorStore = editorStore;

function cleanupNonExistingEditors() {
    for (let i = 0; i < editorStore.length;) {
        const instance = editorStore[i];
        const elem = document.querySelector(`pre[data-editorid="${instance.id}"]`);
        if (elem == null) {
            // console.log(`[cleanupNonExistingEditors] removing id=${instance.id} at index=${i}`);
            editorStore.splice(i, 1);
        } else {
            i++;
        }
    }
}

function decorationsToEditor(instance: IEditorInstance) {
    instance.editor.createDecorationsCollection(
        instance.decorations.map((deco) => {
            return {
                range: new Range(deco.line, 1, deco.line, 1),
                options: {
                    isWholeLine: true,
                    afterContentClassName: `callout callout${deco.conum}`,
                }
            };
        })
    );
}

function createEditor(elem: HTMLElement, code: string) {

    const decoRaw = elem.getAttribute('data-deco');
    const decorations = JSON.parse(decoRaw);

    const targetTheme = isDarkTheme(elem) ? "darkplus" : "vs";
    const editor = monacoEditor.create(elem, {
        value: code, language: 'lapyst',
        theme: targetTheme,
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
        lineNumbers: 'on',
        readOnly: true,
        automaticLayout: true,
        scrollbar: {
            handleMouseWheel: false,
        },
    });

    elem.style.position = 'relative';
    elem.classList.add('readonly');

    function updateSize() {
        const ch = editor.getContentHeight();
        elem.style.height = `${ch}px`;
        elem.style.boxSizing = 'content-box';

        // the -30 are the padding we subtract here...
        const w = elem.getClientRects()[0].width - 30;

        elem.style.setProperty('--code-block-width' , `${w}px`);
        elem.style.setProperty('--code-block-height', `${ch}px`);

        editor.layout({ height: ch, width: w });
    }

    updateSize();

    const instance: IEditorInstance = {
        code,
        decorations,
        flags: elem.getAttribute('data-flags').split(','),
        editor,
        id: nextEditorNum++,
        termState: defaultTerminalState(),
        outputElementRef: null,
    };

    decorationsToEditor(instance);

    elem.setAttribute("data-editorid", instance.id + '');
    editorStore.push(instance);

    return instance;
}

export function getEditorInstance(id: number): IEditorInstance|null {
    for (const instance of editorStore) {
        if (instance.id === id) {
            return instance;
        }
    }
    return null;
}

export function resetEditorInstance(id: number) {
    const instance = getEditorInstance(id);
    if (instance != null) {
        const elem = document.querySelector(`pre[data-editorid="${instance.id}"]`);
        elem.classList.add('readonly');

        instance.editor.updateOptions({ readOnly: true });
        (instance.editor.getModel() as monacoEditor.ITextModel).setValue(instance.code);
        decorationsToEditor(instance);
    }
}

export function runEditorInstance(id: number) {
    const instance = getEditorInstance(id);
    if (instance == null) { return; }
    if (!instance.outputElementRef || !instance.outputElementRef.current) {
        console.error("No output element for editorId", id);
        return;
    }

    instance.outputElementRef.current.textContent = "";
    instance.termState = defaultTerminalState();

    const code = (instance.editor.getModel() as monacoEditor.ITextModel).getValue();
    runnerInstance.enqueueRun(code, id);
}

function appendDom(parent: HTMLElement, html: string): void {
    const el = document.createElement('div');
    el.style.whiteSpace = 'pre-wrap';
    el.innerHTML = html;
    while (el.childNodes.length > 0) {
        parent.appendChild( el.childNodes[0] );
    }
}

export function printEditorInstanceOutput(id: number, text: string) {
    const instance = getEditorInstance(id);
    if (instance == null) { return; }
    if (!instance.outputElementRef || !instance.outputElementRef.current) {
        console.error("No output element for editorId", id);
        return;
    }
    appendDom(instance.outputElementRef.current, term2html(text, instance.termState));
}

export function pinMinHeightEditorInstanceOutput(id: number) {
    const instance = getEditorInstance(id);
    if (instance == null) { return; }
    if (!instance.outputElementRef || !instance.outputElementRef.current) {
        console.error("No output element for editorId", id);
        return;
    }

    const elem = instance.outputElementRef.current;
    elem.style.minHeight = '';
    const h = elem.getClientRects()[0].height;
    elem.style.minHeight = `${h}px`;
}

export function unlockEditorInstance(id: number) {
    const instance = getEditorInstance(id);
    if (instance != null) {
        instance.editor.updateOptions({ readOnly: false });

        const elem = document.querySelector(`pre[data-editorid="${instance.id}"]`);
        elem.classList.remove('readonly');

        if (instance.decorations.length > 0) {
            // get rid of the decorations...
            const m = instance.editor.getModel() as any;
            m.setValue(m.getValue());
        }
    }
}

export function refreshEditorTheme() {
    const targetTheme = document.querySelector('.app').classList.contains(Classes.DARK) ? "darkplus" : "vs";
    monacoEditor.setTheme(targetTheme);
}

let initialized = false;
export async function highlightCodeBlocks() {
    if (!initialized) {
        initialized = true;
        await initMonaco();
    }

    cleanupNonExistingEditors();

    refreshEditorTheme();

    const codeBlocks = document.querySelectorAll<HTMLElement>('pre[data-lang="lapyst"]');
    for (const block of Array.from(codeBlocks)) {
        if (!block.hasAttribute('data-editorid')) {
            const code = block.innerText;
            block.innerHTML = '';
            const instance = createEditor(block, code);

            block.style.marginBottom = '0px';

            // add controls...
            block.insertAdjacentElement('afterend', createControls(instance.id, instance.flags.includes('norun')));
        }
    }
}

export async function relLinksSmoothScroll(container: HTMLElement, navigate: NavigateFunction) {
    const handler = (ev: MouseEvent) => {
        ev.preventDefault();

        const href = (ev.target as HTMLElement).getAttribute('href');

        navigate('.' + href, { relative: 'path' });

        const el = container.querySelector(href);
        if (el != null) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const relLinks = container.querySelectorAll<HTMLElement>('a[href^="#"]');
    for (const link of Array.from(relLinks)) {
        link.onclick = handler;
    }
}

export async function docLinks(container: HTMLElement, navigate: NavigateFunction) {
    const handler = (ev: MouseEvent) => {
        ev.preventDefault();
        const href = (ev.target as HTMLElement).getAttribute('href');
        navigate('../' + href, { relative: 'path' });
    };

    const links = container.querySelectorAll<HTMLElement>('a[href^="./"]');
    for (const link of Array.from(links)) {
        const href = link.getAttribute('href').replace(/\.adoc(?=$|\#|\?)/, '');
        link.setAttribute('href', href);
        link.onclick = handler;
    }
}

export async function permalinkHeaders(container: HTMLElement, toaster: () => Toaster) {
    const headings = container.querySelectorAll<HTMLElement>('h1,h2,h3,h4');
    for (const heading of Array.from(headings)) {
        if (heading.hasAttribute('data-permalinked')) {
            continue;
        }

        const id = heading.getAttribute('id');

        const link = window.location.href.replace(/#.*$/, '') + (id ? `#${id}` : '');
        const icon = createIcon('link', {
            style: { verticalAlign: 'middle' },
            containerTag: 'a',
        });
        icon.classList.add('link');
        icon.title = 'Copy permalink';
        (icon as any).href = link;

        icon.addEventListener('click', (ev) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            navigator.clipboard.writeText(link);
            toaster().show({
                message: 'Successfully copied link',
                intent: 'success',
            });
        });

        heading.insertAdjacentElement('afterbegin', icon);
        heading.setAttribute('data-permalinked', 'true');
    }
}

// DO NOT REMOVE THIS! This will make that the assets are moved from /public to /dist/assets!
const icon_names = {
    note: new URL('/assets/emojis/mutant/info.svg', import.meta.url).href,
    important: new URL('/assets/emojis/mutant/red_exclamation_mark.svg', import.meta.url).href,
    tip: new URL('/assets/emojis/mutant/light_bulb.svg', import.meta.url).href,
    'note.think': new URL('/assets/emojis/mutant/thought_bubble.svg', import.meta.url).href,
    'note.wip': new URL('/assets/emojis/mutant/construction_sign.svg', import.meta.url).href,
};
