import { Button, Card } from '@blueprintjs/core';
import { InfoSign, Home as HomeIcon, SocialMedia, Refresh } from '@blueprintjs/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fake_company_data from '../companygrid';
import { Sidebar } from '../sidebar';

const FakeCompanyGrid = () => {
    const random_company_count = 6;
    const [selection, setSelection] = useState([]);
    const reroll = useCallback(
        () => {
            fake_company_data.sort(() => 0.5 - Math.random())
            setSelection([...new Array(random_company_count).keys()].map((v) => fake_company_data[v]));
        },
        [setSelection]
    );
    useEffect(reroll, []);

    return (
        <section>
            <h3>
                Known and loved from easily recognizeable and everyday brands such as:
                <Button onClick={reroll} style={{ float: 'right' }} title='Re-roll'><Refresh/></Button>
            </h3>
            <div className='fakecompanygrid'>
                {selection.map((entry, idx) => {
                    const html = typeof(entry) === "function" ? entry() : entry;
                    return <Card key={idx} dangerouslySetInnerHTML={{__html: html}}/>;
                })}
            </div>
        </section>
    );
}

export const Home = () => {
    const navigate = useNavigate();

    const omni_info = <InfoSign
        size={12} className='microicon microlnk'
        title='Language philosophy'
        onClick={() => navigate('/language/philosophy')}
    />;
    const homepage_icon = <HomeIcon
        size={14} className='microlnk'
        title='https://info.lapyst.dev'
        onClick={() => window.open('https://info.lapyst.dev', '_blank')}
    />;
    const social_icon = <SocialMedia
        size={14} className='microlnk'
        title='https://soc.saiyajin.space/@mai_lapyst'
        onClick={() => window.open('https://soc.saiyajin.space/@mai_lapyst', '_blank')}
    />;

    return (
        <div className='home'>
            <Sidebar desktop_hidden/>
            <div className='introbanner'>
                <section style={{ display: 'flex' }}>
                    <div>
                        <p style={{ lineHeight: '1.4em' }}>
                            Lapyst is a <span className='micro inline_text_super'>(the first TM)</span> general-purpose omni-programming language{omni_info},
                            with static/dynamic <span className='micro inline_text_super'>(wip TM)</span> typing,
                            endless backends <span className='micro inline_text_super'>(hyper wip TM)</span> including a interpreter, llvm and many more to come!
                        </p>
                        <div style={{ margin: '20px auto', maxWidth: 'fit-content' }}>
                            <Button style={{ fontSize: '1.18rem' }} onClick={() => navigate('/downloads')} title='downloawd time :3' large>
                                Just cram it onto my harddrive already!
                            </Button>
                            <div className='micro' style={{ textAlign: 'center', marginTop: '0.3rem' }}>This takes you to the downloads btw</div>
                        </div>
                    </div>
                    <div className='codeexample'>
                        <span>Just imagine here is some cute example code &lt;3</span>
                        <span className='micro' style={{ marginTop: '0.3rem' }}>And you'll love it, or.... you dont get cookies</span>
                    </div>
                </section>
            </div>
            <section style={{ marginTop: '20px', lineHeight: '1.4em' }}>
                The lapyst language was created by @mai_lapst {homepage_icon} {social_icon} in an ongoing attempt to create the most cute,
                but also powerfull, versatile and overall cool-ass-looking language out there. <span className='micro inline_text_super'>(or atleast what she's sees as such!)</span> To
                be blankly honest, it's just an attempt to cram as much features into one thing as catgirly as pawsible :3 If you wanna know more about
                the <Link to={'/language/philosophy'}>philosophy</Link> behind the lapyst language, including how the name came to be and so much more, head straight
                to <Link to={'/language/philosophy'}>this page</Link>.
            </section>
            <FakeCompanyGrid/>
        </div>
    );
};
