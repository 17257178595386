import React, { useCallback, useEffect, useRef } from 'react';
import { SiteFooter } from '../components';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { docLinks, highlightCodeBlocks, permalinkHeaders, relLinksSmoothScroll } from '../docshelper';
import { Sidebar as SiteSidebar } from '../sidebar';
import { OverlayToaster } from '@blueprintjs/core';
import { nav_data, NavNode } from 'lapyst/langref.jsx';

const Sidebar = () => {
    const location = useLocation();
    const elements = nav_data.map((node: NavNode, idx: number) => {
        const margin = node.level * 10;
        return <Link
            key={idx}
            to={node.route}
            className={ location.pathname.startsWith(node.route) ? 'current' : '' }
            style={{
                marginLeft: margin==0 ? '0' : `${margin}px`,
                display: 'block',
                marginTop: '8px',
            }}
        >{node.title}</Link>
    });

    return (
        <SiteSidebar mode='langref'>
            {elements}
        </SiteSidebar>
    );
};

const arrow_up_svg = new URL('/assets/emojis/mutant/arrow_up.svg', import.meta.url).pathname;

const Content = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const contentRef = useRef<HTMLDivElement>(null);
    const sbttRef = useRef<HTMLDivElement>(null);
    const toasterRef = useRef<OverlayToaster>(null);

    useEffect(() => {
        (async () => {
            if (!document.body.hasAttribute('data-prod')) return;
            await highlightCodeBlocks();
            await relLinksSmoothScroll(contentRef.current, navigate);
            await docLinks(contentRef.current, navigate);
            await permalinkHeaders(contentRef.current, () => toasterRef.current);
        })()
    }, [location.pathname]);

    useEffect(() => {
        if (location.hash != "#" && location.hash != "") {
            setTimeout(() => {
                const el = contentRef.current.querySelector(location.hash);
                if (el != null) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            }, 200);
        }
    }, [contentRef.current, location.hash]);

    const scrollHandler = useCallback(
        (ev: React.UIEvent) => {
            const st = (ev.target as HTMLElement).scrollTop;
            if (st > 20) {
                sbttRef.current.style.display = 'block';
            } else {
                sbttRef.current.style.display = 'none';
            }
        },
        []
    );

    const scrollToTop = useCallback(() => {
        contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='content adoc-container' ref={contentRef} onScroll={scrollHandler} >
            <Outlet/>
            <OverlayToaster ref={toasterRef} position='bottom'/>
            <div className='sbtt' ref={sbttRef} onClick={scrollToTop}><img width={48} alt="back to top" src={arrow_up_svg}/></div>
            <SiteFooter/>
        </div>
    );
};

export const LanguageReference = () => {
    return (
        <div className='langref'>
            <Sidebar/>
            <Content/>
        </div>
    );
};