import { getEditorInstance, pinMinHeightEditorInstanceOutput, printEditorInstanceOutput } from "./docshelper";

interface SourceFile {
    path: string;
    content: string;
}

interface RunRequest {
    editorId: number;
    files: SourceFile[];
    entryFilepath: string;
}

class LapystRunner {
    private worker: any = null;

    constructor() {
        this.onWorkerError = this.onWorkerError.bind(this);
        this.onWorkerMessage = this.onWorkerMessage.bind(this);
    }

    postMessage(msg): void {
        this.worker.postMessage(msg);
    }

    onWorkerError(e): void {
        console.error("Got error from worker:", e);
        this.terminate();
    }

    onWorkerMessage(e): void {
        if (e.currentTarget != this.worker) { return; }

        if (typeof(this[e.data.function]) === "function") {
            this[e.data.function](e.data);
        }
    }

    public start(): void {
        try {
            this.terminate();
            this.worker = new Worker(new URL('./worker.ts', import.meta.url), { name: 'lapystc', type: 'module' });
            this.worker.onerror = this.onWorkerError;
            this.worker.onmessage = this.onWorkerMessage;
            this.postMessage({ function: 'start' });
        } catch (e) {
            console.error("Error:", e);
        }
    }

    public terminate(): void {
        console.log("Terminating lapystc ...");
        if (this.worker) { this.worker.terminate(); }
        this.worker = null;
    }

    public ensureRunning(): void {
        if (this.worker == null) {
            this.start();
        }
    }

    public enqueueRun(code: string, editorId: number): void {
        this.ensureRunning();
        const req: RunRequest = {
            editorId,
            entryFilepath: 'src.lp',
            files: [
                {
                    path: 'src.lp',
                    content: code,
                }
            ],
        };
        this.postMessage({ function: 'compile_and_run', req });
    }

    print({ text, editorId }: { text: string, editorId: number }) {
        printEditorInstanceOutput(editorId, text);
    }

    print_err({ text, editorId }: { text: string, editorId: number }) {
        printEditorInstanceOutput(editorId, text);
    }

    app_done({ editorId }: { editorId: number }) {
        pinMinHeightEditorInstanceOutput(editorId);
    }
}

export const runnerInstance = new LapystRunner();
