const data = [
    'That one shady shop in the backally<br/><span class="mini">you know <strong>exactly</strong> which one!</span>',
    /* () => {
        var name = 'mother';
        if (Math.random() <= 0.4) {
            name = 'father';
        }
        else if (Math.random() <= 0.2) {
            name = 'other mother';
        }
        return "Your " + name;
    }, */
    'That one guy from college that still didn\'t got the joke.<br/><span class="mini">if you know nobody, you\'re it!</span>',
    'Your college\'s terrible wifi-router<br/><span class="mini">It\'s a miracle it hasn\'t blown up until now</span>',
    'The roomba from the nighboors<br/><span class="mini">that one that runs like at 2am</span>',
    'Michael Jackson<br><span class="mini">he-heee!</span>',
    'Netfuck<br/><span class="mini">Brainfuck but <s>better</s><i>...different...</i></span>',
    'That one printer that just keeps running out of ink',
    'Isekai Inc.<br/><span class="mini">We make the heros of tomorow!</span>',
    '[object Object]<br/><span class="mini">Inf.... NaN</span>',
    'Space Ninjas<br/><span class="mini">they\'re ninjas.... IN SPACE!!!!!</span>',
    'Wobbly Willy<br/><span class="mini">you <strong>really</strong> dont wanna know</span>',
    'Rick Astley<br/><span class="mini">\'couse he\'ll never gonna give you up!</span>',
    '<a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank"><i>*click*</i></a>'
    // TODO: think harder for more; and make it so that some are more rare then others!
];

export default data;