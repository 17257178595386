import React, { useCallback, useEffect, useState } from 'react';
import { IconLink } from './components';
import { Box, Cloud, Code, Console, Download, IconName, Lightbulb, Manual, SmallCross } from '@blueprintjs/icons';
import { setSidebarToggleCallback } from './layout';
import { Button } from '@blueprintjs/core';

export type SidebarMode = 'langref';

export interface ISidebarProps extends React.HTMLProps<HTMLDivElement> {
    mode?: SidebarMode;
    desktop_hidden?: boolean;
}

interface ISection {
    title: string;
    icon: JSX.Element;
    to?: string;
    disabled?: boolean;
    kind?: string;
    extern?: boolean;
}

const sections: ISection[] = [
    { title: 'Language Reference', icon: <Manual/>, to: '/language/ref', kind: 'langref' },
    { title: 'lapystc cli Reference', icon: <Console/>, disabled: true },
    { title: 'Spark package Reference', icon: <Box/>, disabled: true },
    { title: 'spark cli Reference', icon: <Console/>, disabled: true },
    { title: 'Downloads', icon: <Download/>, to: '/downloads' },
    { title: 'Editor support', icon: <Code/>, disabled: true },
    { title: 'Playground', icon: <Cloud/>, to: 'https://lang.lapyst.dev/playground', extern: true },
    { title: 'Philoshophy', icon: <Lightbulb/>, to: '/language/philosophy' },
];

export const Sidebar = ({ mode, desktop_hidden, children, ...props }: ISidebarProps) => {
    var className = 'sidebar offcanvas-lg offcanvas-start';
    if (desktop_hidden) { className += ' desktopHidden'; }

    const [ open, setOpen ] = useState(false);
    if (open) { className += ' open'; }

    useEffect(() => {
        const callback = () => { setOpen(true); };
        setSidebarToggleCallback(callback);
        return () => { setSidebarToggleCallback(null); };
    }, [setOpen]);

    const handleClose = useCallback(() => { setOpen(false); }, [setOpen]);

    return (
        <div className={className} {...props}>
            <div className='header'>
                <span>Lapyst Language</span>
                <Button
                    minimal icon={<SmallCross/>}
                    onClick={handleClose}
                    title='Close navigation menu'
                />
            </div>
            <div className='body'>
                {sections.map((section, idx) => {
                    const section_children = section.kind && section.kind === mode ? children : null;
                    return <div key={idx}>
                        <IconLink
                            to={section.to} icon={section.icon}
                            disabled={section.disabled} className='mobileLnk'
                            extern={section.extern}
                            onClick={handleClose}
                        >{section.title}</IconLink>
                        <div className='secBody'>{section_children}</div>
                    </div>;
                })}
            </div>
        </div>
    );
}
