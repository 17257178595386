import React from 'react';
import { ExtLink } from '../components';
import { Sidebar } from '../sidebar';

export default () => {
    return (
        <div className='attributions' style={{ marginTop: '20px', fontSize: '1.05rem' }}>
            <Sidebar desktop_hidden/>
            <section>
                <h2>Attributions</h2>
                <p>
                    This page was built with:
                </p>
                <ul>
                    <li><ExtLink href='https://react.dev/' text='react'/></li>
                    <li><ExtLink href='https://blueprintjs.com/' text='blueprintjs'/></li>
                    <li><ExtLink href='https://vitejs.dev/' text='vitejs'/></li>
                    <li><ExtLink href='https://www.typescriptlang.org/' text='typescript'/></li>
                    <li><ExtLink href='https://reactrouter.com/en/main' text='react-router-dom'/></li>
                    <li><ExtLink href='https://mutant.tech' text='Mutant Standard Font 2023.12'/></li>
                    <li><ExtLink href='https://microsoft.github.io/monaco-editor/' text='monaco-editor'/></li>
                </ul>
                <p>
                    Tools used:
                </p>
                <ul>
                    <li><ExtLink href='https://sass-lang.com/' text='sass'/></li>
                    <li><ExtLink href='https://docs.asciidoctor.org/asciidoctor.js/latest/' text='asciidoctor.js'/></li>
                </ul>
                <p>
                    Additional ressources used:
                </p>
                <ul>
                    <li><ExtLink href='https://archlinux.org/art/' text='Archlinux logo'/> / <ExtLink href='https://sources.archlinux.org/other/artwork/archlinux-artwork-1.6.tar.gz' text='Sources'/></li>
                    <li><ExtLink href='https://commons.wikimedia.org/wiki/File:Tux_Classic_flat_look_v1.1.svg?uselang=en#Licensing' text='Linux / Tux logo from Wikipedia commons'/></li>
                </ul>
                <p>
                    The source of this site is licensed under <ExtLink href='https://codeark.it/Lapyst/lang.lapyst.dev/src/branch/master/LICENSE' text='AGPL-3.0-or-later'/>.
                    It can be found at <ExtLink href='https://codeark.it/Lapyst/lang.lapyst.dev' text='this git repo'/>.
                </p>
            </section>
        </div>
    );
}
