import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { LanguagePhilosophy } from './pages/philosophy';
import Layout from './layout';
import { Downloads, OlderDownloadsList } from './pages/downloads';
import { Home } from './pages/home';
import Attributions from './pages/attributions';
import { LanguageReference } from './pages/langref';
import { Error404 } from './components';

import * as langref from 'lapyst/langref.jsx';

export default () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home/>} />
                    <Route path="/downloads" element={<Downloads/>} />
                    <Route path="/downloads/full-list" element={<OlderDownloadsList/>} />
                    <Route path="/language/philosophy" element={<LanguagePhilosophy/>} />
                    <Route path="/attributions" element={<Attributions/>} />
                    <Route path="/language/ref" Component={LanguageReference}>
                        <Route index element={<Navigate to={'/language/ref/syntax/introduction'}/>}/>
                        <Route path='syntax/introduction' Component={langref.syntax_introduction} />
                        <Route path='syntax/lexical_elements' Component={langref.syntax_lexical_elements} />
                        <Route path='syntax/types' Component={langref.syntax_types} />
                        <Route path='syntax/declarations' Component={langref.syntax_declarations} />
                        <Route path='syntax/expressions' Component={langref.syntax_expressions} />
                        <Route path='syntax/statements' Component={langref.syntax_statements} />
                        <Route path='syntax/units' Component={langref.syntax_units} />
                        <Route path='syntax/annotations' Component={langref.syntax_annotations} />
                        <Route path='syntax/macros' Component={langref.syntax_macros} />
                        <Route path='*' Component={Error404} />
                    </Route>
                    <Route path='*' element={<Error404/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
