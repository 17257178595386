import { Card, Classes, Tag } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar } from '../sidebar';
import { Cloud } from '@blueprintjs/icons';

const tux_svg = new URL('/assets/img/Tux.svg', import.meta.url).pathname;
const data_releases = '/data/releases.json';

const archlinux_icon_tango_svg = new URL('/assets/img/archlinux-icon-tango-128.svg', import.meta.url).pathname;

type DownloadKind = 'archlinux' | 'linux_generic';

interface DownloadEntry {
    filename: string
    location: string
    kind: DownloadKind
}

function downloadKindToText(kind: DownloadKind): string {
    switch (kind) {
        case 'archlinux': return 'Archlinux package';
        case 'linux_generic': return 'Generic linux archive';
    }
}

interface ReleaseInfo {
    version: string
    latest: boolean
    pre_release?: boolean
    date: string
    changelog?: string
    downloads?: DownloadEntry[]
}

const release_info_data = (
    fetch(data_releases)
        .catch((reason) => {
            console.error('failed to fetch releases: ', reason);
        })
        .then((resp: Response) => {
            return resp.json() as Promise<ReleaseInfo[]>;
        })
);

function findLatestFor(kind: DownloadKind, data: ReleaseInfo[]): [ReleaseInfo,DownloadEntry] | 'none' {
    for (const inf of data) {
        if (!inf.downloads) { continue; }
        for (const download of inf.downloads) {
            if (download.kind === kind) {
                return [inf, download];
            }
        }
    }
    return 'none';
}

export const OlderDownloadsList = () => {
    const [ list, setList ] = useState<ReleaseInfo[]>([]);

    useEffect(() => {
        release_info_data.then((releases: any) => setList(releases))
    }, []);

    const cards = list
        .map((inf: ReleaseInfo) => {
            const downloads =
                (inf.downloads || []).map((download: DownloadEntry) => {
                    return <li key={download.filename}>
                        <a href={download.location} target='_blank'>{download.filename}</a>
                        <span style={{ marginLeft: '4px', fontStyle: 'italic' }} className={Classes.TEXT_MUTED}>({downloadKindToText(download.kind)})</span>
                    </li>
                });

            const latest_badge = (inf.latest && !inf.pre_release)
                ? <Tag round minimal intent='success' style={{ verticalAlign: 'bottom', marginLeft: '8px' }}>Latest</Tag>
                : null;

            const prerelease_badge = inf.pre_release
                ? <Tag round minimal intent='warning' style={{ verticalAlign: 'bottom', marginLeft: '8px' }}>Pre-Release</Tag>
                : null;

            const changelog = inf.changelog
                ? <details><summary>Changelog</summary><p style={{ marginTop: '10px' }}>{inf.changelog}</p></details>
                : <p>No changelog available</p>

            return <Card key={inf.version}>
                <h3 style={{ margin: '0px' }}>v{inf.version} {latest_badge}{prerelease_badge}</h3>
                <div style={{ marginBottom: '10px', fontStyle: 'italic' }}>Released: {inf.date}</div>
                {changelog}
                {
                    downloads.length > 0
                        ? <div style={{ marginTop: '8px' }}><div>Downloads:</div><ul>{downloads}</ul></div>
                        : <div style={{ marginTop: '8px', fontStyle: 'italic' }} className={Classes.TEXT_MUTED}>No downloads available for this release</div>
                }
            </Card>
        });

    return (
        <div className='older_downloads' style={{ marginTop: '20px' }}>
            <section style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {cards.length > 0 ? cards : <Card>No download history</Card>}
            </section>
        </div>
    );
};

export const Downloads = () => {
    type ILatestData = {[key: string]: [ReleaseInfo, DownloadEntry] | 'none'};

    const [ latest, setLatest ] = useState<ILatestData>({});

    useEffect(() => {
        release_info_data.then((data) => {
            setLatest({
                'archlinux': findLatestFor('archlinux', data),
                'linux_generic': findLatestFor('linux_generic', data),
            });
        });
    }, []);

    var latest_arch = null;
    if (latest['archlinux']) {
        if (latest['archlinux'] === 'none') {
            latest_arch = <i className={Classes.TEXT_MUTED}>No downloads available at the moment</i>;
        } else {
            const [inf, download] = latest['archlinux'];
            latest_arch = <a href={download.location}>{download.filename}</a>;
        }
    } else {
        latest_arch = <span className={Classes.SKELETON}>lapyst-1.0.0-1_x86_64.pkg.tar.zst</span>;
    }

    var latest_linux_generic = null;
    if (latest['linux_generic']) {
        if (latest['linux_generic'] === 'none') {
            latest_linux_generic = <div>
                <p className={Classes.TEXT_MUTED} style={{ fontStyle: 'italic' }}>No downloads available at the moment</p>
                <code style={{ fontSize: '0.8rem', visibility: 'hidden' }}>sudo tar -xf lapyst-1.0.0.tar.gz -C /usr/local</code>
            </div>;
        } else {
            const [inf, download] = latest['linux_generic'];
            latest_linux_generic = <div>
                <p>Download <a href={download.location}>{download.filename}</a>, and run:</p>
                <code style={{ fontSize: '0.8rem' }}>sudo tar -xf {download.filename} -C /usr/local</code>
            </div>;
        }
    } else {
        latest_linux_generic = <div className={Classes.SKELETON}>
            <p>Download lapyst-1.0.0.tar.gz, and run:</p>
            <code style={{ fontSize: '0.8rem' }}>sudo tar -xf lapyst-1.0.0.tar.gz -C /usr/local</code>
        </div>;
    }

    return (
        <div className='downloads'>
            <Sidebar desktop_hidden/>
            <section className='download_grid'>
                <Card>
                    <img src={archlinux_icon_tango_svg} alt="archlinux icon tango" height={128}></img>
                    <h3>ArchLinux package</h3>
                    <p>
                        Ideal to get started under archlinux (the best)
                    </p>
                    <ul style={{ paddingLeft: '18px' }}>
                        <li>{latest_arch}</li>
                        <li>pacman repo comming soon <span className='micro'>TM</span></li>
                    </ul>
                </Card>
                <Card>
                    <img src={tux_svg} alt="linux tux logo" height={128}></img>
                    <h3>Plain linux package</h3>
                    <p>
                        Last resort for your distro.<br/>
                        If not... well its building time then :D<br/>
                    </p>
                    {latest_linux_generic}
                </Card>
                <Card className='playgroundCard'>
                    <Cloud size={128} style={{ display: 'block', margin: 'auto', width: '128px', color: 'plum' }}/>
                    <h3>Try it online</h3>
                    <p>
                        Try out phenomenal, cutting edge, <span className='micro'>not so</span> AI based cloudplatform! Is fast, its easy and its free!!!!!!<br/>
                        <span className='micro' style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>and also: it's a playground</span><br/>
                        <br/>
                        <a className='bp5-button bp5-fill' href='https://lang.lapyst.dev/playground' target='_blank'>Start today!!!</a>
                    </p>
                </Card>
            </section>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                More packages maybe comming when demand is met.<br/>
                Or whenever I (mai) need one new; maybe even never. Be warned :D<br/>
                <br/>
                As an apology you get a cookie: 🍪<br/>
                <br/>
                You also can browse <Link to='/downloads/full-list'>the full list</Link>
            </div>
        </div>
    );
};
