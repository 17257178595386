import React, { Suspense } from 'react';
import { createRoot, hydrateRoot, Root } from 'react-dom/client';

import App from './app';

const rootElem: HTMLElement | null = document.getElementById('container');
if (rootElem == null) {
    alert("Could not find container!");
} else {
    if (rootElem.hasChildNodes()) {
        hydrateRoot(rootElem, <App/>);
    } else {
        const root: Root = createRoot(rootElem);
        root.render(<Suspense><App/></Suspense>);
    }
}
